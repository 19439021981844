// Server Lights
const decideFirstCluster = (rnd) => {
  if (rnd >= 0 && rnd < 0.25) return '#f00'; // red
  if (rnd >= 0.25 && rnd < 0.5) return '#0f0'; // green
  if (rnd >= 0.5 && rnd < 0.75) return '#F7B500'; // yellow
  return 'gray';
};

const decideSecondCluster = (rnd) => {
  if (rnd >= 0 && rnd < 0.35) return '#f00';
  if (rnd >= 0.35 && rnd < 0.6) return '#0f0';
  if (rnd >= 0.6 && rnd < 0.75) return 'gray';
  return '#F7B500';
};

const decideThirdCluster = (rnd) => {
  if (rnd >= 0 && rnd < 0.3) return '#F7B500';
  if (rnd >= 0.3 && rnd < 0.5) return '#0f0';
  if (rnd >= 0.5 && rnd < 0.75) return 'gray';
  return '#f00';
};

const decideServerLights = () => {
  const rnd = Math.random();
  const clusters = [];
  clusters.push(decideFirstCluster(rnd));
  clusters.push(decideSecondCluster(rnd));
  clusters.push(decideThirdCluster(rnd));

  return clusters;
};

const cleanHtml = (htmlString) =>
  !!htmlString && htmlString.replace(/<\/?[^>]+(>|$)/g, '');

const adjustCamera = (
  cameraApi,
  controlsApi,
  cameraRef,
  controlsRef,
  cameraFinal,
  controlsFinal,
  lock = true
) => {
  if (lock) {
    controlsRef.current.enableZoom = false;
    controlsRef.current.enableRotate = false;
  } else {
    controlsRef.current.enableZoom = true;
    controlsRef.current.enableRotate = true;
  }

  cameraApi.start({
    from: {
      position: [
        cameraRef.current.position.x,
        cameraRef.current.position.y,
        cameraRef.current.position.z,
      ],
    },
    to: { position: cameraFinal },
  });

  controlsApi.start({
    from: {
      target: [
        controlsRef.current.target.x,
        controlsRef.current.target.y,
        controlsRef.current.target.z,
      ],
    },
    to: { target: controlsFinal },
  });
};

const postMessage = (iframeId, msg) => {
  const el = document.getElementById(iframeId);
  if (!el) return;

  el.contentWindow.postMessage(msg, '*');
}

export { decideServerLights, cleanHtml, adjustCamera, postMessage };

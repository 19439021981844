import { useGLTF, Plane, Html } from '@react-three/drei';

import { OS_ENDPOINT } from '../constants';

const GlobeMonitor = ({ iframeId,disable, ...props }) => {
  const { nodes, materials } = useGLTF('/models/globe-monitor.glb');

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane079.geometry}
        material={materials['PC PLASTIC.001']}
      />
      <mesh
        geometry={nodes.Plane079_2.geometry}
        material={materials['PC PLASTIC 02.001']}
      />
      <Plane
        rotation-y={-Math.PI * 0.02}
        position={[-0.02, 0.27, 0.2]}
        args={[0.79, 0.55]}
      >
        <meshBasicMaterial color='red' />
        <Html
          transform
          occlude='blending'
          distanceFactor={1}
          position={[0, 0, 0.005]}
          className='w-[328px] h-[230px]'
          style={{ pointerEvents: disable ? 'none' : 'auto'}}
          zIndexRange={[2, 10]}
        >
          <iframe
            id={iframeId}
            title='embed'
            src={`${OS_ENDPOINT}/mehanet`}
            className='w-full h-full'
          ></iframe>
        </Html>
      </Plane>
    </group>
  );
};

export default GlobeMonitor;

useGLTF.preload('/models/globe-monitor.glb');

import { createPortal } from 'react-dom';
import { forwardRef, useState } from 'react';
import { X } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';

import { Response } from '../../components';
import { mutateLaunch } from '../../lib';
import { LaunchSchema } from '../../schemas';
import { useDomReady } from '../../hooks';
import { useRocket } from '../../context';

const LaunchForm = forwardRef(({ onClose }, ref) => {
  const isDomReady = useDomReady();

  const { setLaunching, isLanding, isLaunching } = useRocket();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(LaunchSchema),
    defaultValues: { name: '', email: '', mobile: '' },
  });

  const [err, setErr] = useState(null);

  const { mutate, isPending } = useMutation({
    mutationFn: mutateLaunch,
    onSuccess: async (data) => {
      const res = await data.json();
      if (res.errors) return setErr(res.errors);

      onClose();
      setLaunching(true);
    },
    onError: ({ message }) => setErr([message]),
  });

  const onSubmit = ({ name, email, mobile }) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('mobile', mobile);

    mutate(formData);
  };

  const pendingText = isPending ? 'ignition...' : 'start engine';
  const buttonText =
    isLaunching || isLanding ? 'wait until rocket lands...' : pendingText;

  if (!isDomReady) return null;
  return createPortal(
    <form
      ref={ref}
      className="fixed top-1/2 left-1/2 z-[101] w-[95%] md:w-3/4 lg:w-1/2 rounded-md
    bg-primary text-white font-kode py-6 px-4 flex flex-col items-center shadow-xl"
      onSubmit={handleSubmit(onSubmit)}
    >
      <button
        className="absolute top-0 right-[5%] bg-primary rounded-full 
        w-14 h-14 translate-y-[-50%] flex justify-center items-center"
        onClick={onClose}
      >
        <X className="w-8 h-8" />
      </button>
      <div className="text-center mb-5">
        <h1 className="lg:text-xl font-semibold border-b-2 pb-1">
          MRZ Launch System
        </h1>
        <p className="text-xs pt-1">
          launch the <span className="text-base">&#128640;</span> by joining our
          newsletter!
        </p>
      </div>
      <div className="w-full lg:w-3/4">
        <label htmlFor="launch-name" className="text-sm lg:text-base">
          Your Name:
        </label>
        <input
          id="launch-name"
          type="text"
          placeholder="John Doe"
          className="w-full mt-1 bg-primary-light rounded-sm py-1 px-3 lg:py-2 lg:px-4"
          {...register('name')}
        />
        {errors.name && (
          <p className="text-xs font-bold text-red-600 mt-2">
            {errors.name.message}
          </p>
        )}
      </div>
      <div className="w-full lg:w-3/4 my-5">
        <label htmlFor="launch-email" className="text-sm lg:text-base">
          Your Email:
        </label>
        <input
          id="launch-email"
          type="email"
          placeholder="johndoe@example.com"
          className="w-full mt-1 bg-primary-light rounded-sm py-1 px-3 lg:py-2 lg:px-4"
          {...register('email')}
        />
        {errors.email && (
          <p className="text-xs font-bold text-red-600 mt-2">
            {errors.email.message}
          </p>
        )}
      </div>
      <div className="w-full lg:w-3/4">
        <label htmlFor="launch-mobile" className="text-sm lg:text-base">
          Your Phone Number:
        </label>
        <PhoneInputWithCountry
          id="mobile"
          name="mobile"
          control={control}
          className="w-full mt-1 bg-primary-light rounded-sm py-1 px-3 lg:py-2 lg:px-4"
          defaultCountry="GB"
          international={false}
        />
        {errors.mobile && (
          <p className="text-xs font-bold text-red-600 mt-2">
            {errors.mobile.message}
          </p>
        )}
      </div>
      <button
        className="my-5 bg-secondary px-3 py-1 text-sm lg:text-base rounded-sm
      hover:bg-secondary-light duration-100 disabled:bg-gray-400 disabled:cursor-not-allowed"
        disabled={isPending || isLaunching || isLanding}
      >
        {buttonText}
      </button>
      {err && <Response isError messages={err} />}
    </form>,
    document.getElementById('home-wrapper')
  );
});

export default LaunchForm;

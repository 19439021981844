import { useRef, useState, useMemo } from 'react';
import { Phone, FileCode2 } from 'lucide-react';
import { useTransition, animated as a } from '@react-spring/web';

import { Backdrop } from '../../components';
import { LaunchForm } from '../components';
import { postMessage } from '../../utils';

const Controller = ({ isOnMonitor, onContact, onOrder }) => {
  const AnimatedLaunchForm = useMemo(() => a(LaunchForm), []);
  const AnimatedBackdrop = useMemo(() => a(Backdrop), []);

  const wrapperRef = useRef(null);
  const notPressedRef = useRef(null);
  const pressedRef = useRef(null);

  const [isLaunchFormOpen, setIsLaunchFormOpen] = useState(false);

  const launchFormTransition = useTransition(isLaunchFormOpen, {
    from: { y: -1000, transform: 'translate(-50%, -50%)' },
    enter: { y: 0, transform: 'translate(-50%, -50%)' },
    leave: { y: -1000, transform: 'translate(-50%, -50%)' },
  });

  const backdropTransition = useTransition(isLaunchFormOpen, {
    from: { opacity: 0 },
    enter: { opacity: 0.7 },
    leave: { opacity: 0 },
  });

  const handleLaunchFormOpen = () => {
    if (notPressedRef.current && pressedRef.current) {
      notPressedRef.current.style.display = 'none';
      pressedRef.current.style.display = 'inline-block';

      setTimeout(() => {
        notPressedRef.current.style.display = 'inline-block';
        pressedRef.current.style.display = 'none';
      }, 150);
    }

    setIsLaunchFormOpen(true);
  };

  const handleLaunchFormClose = () => setIsLaunchFormOpen(false);

  return (
    <div
      className="fixed bottom-5 left-1/2 translate-x-[-50%] z-50 flex items-center
      scale-95 md:scale-100"
    >
      {backdropTransition(
        (style, item) =>
          item && (
            <AnimatedBackdrop style={style} onClick={handleLaunchFormClose} />
          )
      )}
      {launchFormTransition(
        (style, item) =>
          item && (
            <AnimatedLaunchForm style={style} onClose={handleLaunchFormClose} />
          )
      )}
      <div
        className={`w-40 h-20 -z-20 translate-y-1 duration-500
        ${isOnMonitor ? 'translate-x-4' : 'translate-x-10'}`}
      >
        <img
          src="/images/controller-button.svg"
          alt=""
          className="w-full h-full rotate-180 absolute -z-10"
        />
        <button
          className="w-full h-full flex gap-x-1 justify-center items-center
          text-white -translate-x-2"
          onClick={() => {
            onContact();
            postMessage('home-iframe', 'openContact');
          }}
        >
          <Phone className="w-5 h-5" />
          <span className="font-kode text-sm">Contact</span>
        </button>
      </div>
      <div
        className="w-24 h-24 bg-[#b1b1b1] rounded-full before:w-full relative
        before:h-full before:absolute before:bg-[#6e6e6f] before:rounded-full
        before:-z-10 before:translate-y-[6px]"
      >
        <div
          className="w-4/5 h-4/5 absolute top-1/2 left-1/2 -translate-x-1/2 
          -translate-y-[55%] before:absolute before:w-full before:h-full before:bg-black
          before:rounded-full before:translate-y-[6px] cursor-pointer"
          onClick={handleLaunchFormOpen}
        >
          <img
            ref={notPressedRef}
            src="/images/launch-btn.png"
            alt=""
            className="-translate-y-2 absolute"
          />
          <img
            ref={pressedRef}
            src="/images/launch-btn-pressed.png"
            alt=""
            className="absolute hidden translate-y-[2px]"
          />
        </div>
      </div>
      <div
        className={`w-40 h-20 -z-20 translate-y-1 duration-500
        ${isOnMonitor ? '-translate-x-4' : '-translate-x-10'}`}
      >
        <img
          src="/images/controller-button.svg"
          alt=""
          className="w-full h-full absolute -z-10"
        />
        <button
          className="w-full h-full flex gap-x-1 justify-center items-center
          text-white translate-x-2"
          onClick={() => {
            onOrder();
            postMessage('services-iframe', 'openOrder');
          }}
        >
          <FileCode2 className="w-5 h-5" />
          <span className="font-kode text-sm">Order</span>
        </button>
      </div>
    </div>
  );
};

export default Controller;

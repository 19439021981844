import { z } from 'zod';

export const LaunchSchema = z.object({
  name: z
    .string()
    .min(1, 'Name is required.')
    .max(64, 'Name cannot be longer than 64 characters.'),
  email: z.string().min(1, 'Email is required.').email('Invalid email.'),
  mobile: z.string().min(1, 'Valid phone number is required.'),
});

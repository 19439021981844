import { useGLTF, Html, Plane } from '@react-three/drei';

import { OS_ENDPOINT } from '../constants';

const RadarMonitor = ({ iframeId, disable, ...props }) => {
  const { nodes, materials } = useGLTF('/models/radar-monitor.glb');

  return (
    <group {...props} dispose={null}>
      <group scale={0.675}>
        <mesh
          geometry={nodes.Plane072.geometry}
          material={materials.PaletteMaterial001}
        />
        <mesh
          geometry={nodes.Plane072_1.geometry}
          material={materials.PaletteMaterial002}
        />
        <mesh
          geometry={nodes.Plane072_3.geometry}
          material={materials.PaletteMaterial005}
        />
        <mesh
          geometry={nodes.Plane072_4.geometry}
          material={materials.PaletteMaterial003}
        />
        <mesh
          geometry={nodes.Plane072_5.geometry}
          material={materials.PaletteMaterial004}
        />
      </group>
      <Plane
        args={[1.05, 0.7]}
        rotation-y={Math.PI}
        position={[0.075, 0.72, -0.1]}
      >
        <meshBasicMaterial color='black' />
        <Html
          transform
          occlude='blending'
          distanceFactor={1}
          position={[0, 0.01, 0.02]}
          className='w-[410px] h-[280px]'
          style={{ pointerEvents: disable ? 'none' : 'auto'}}
          zIndexRange={[2, 10]}
        >
          <iframe
            id={iframeId}
            title='embed'
            src={`${OS_ENDPOINT}/mehaholding`}
            className='w-full h-full'
          ></iframe>
        </Html>
      </Plane>
    </group>
  );
};

export default RadarMonitor;

useGLTF.preload('/models/radar-monitor.glb');

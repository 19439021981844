import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '../constants';

const useLanding = () =>
  useQuery({
    queryKey: ['landing'],
    queryFn: () => fetch(`${ENDPOINT}/api/landing`).then((res) => res.json()),
  });

const mutateLaunch = (formData) =>
  fetch(`${ENDPOINT}/api/flight`, {
    method: 'POST',
    body: formData,
  });

export { useLanding, mutateLaunch };

import { Suspense, useState } from 'react';
import { useProgress } from '@react-three/drei';

import { animated as a, useTransition } from '@react-spring/web';

import { progressSentences } from '../constants';
import { useIntro } from '../context';

const Intro = ({ children }) => {
  const { progress } = useProgress();
  const [show, setShow] = useState(process.env.NODE_ENV === 'production');
  const { setRemoved } = useIntro();

  const transition = useTransition(show, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
    onDestroyed: setRemoved,
  });

  const coefficient = 100 / progressSentences.length;
  const isLoaded = progress === 100;

  return (
    <>
      <Suspense fallback={null}>{children}</Suspense>
      {transition(
        (style, item) =>
          item && (
            <a.div
              className='w-full h-screen bg-black fixed top-0 left-0 z-[100000]
              text-white font-kode px-2 py-4 flex flex-col'
              style={style}
            >
              <p className='text-sm md:text-base xl:text-lg font-bold tracking-wider mb-1'>
                MRZ OS (Version 0.1.14-Beta)
              </p>
              <p className='text-xs md:text-sm xl:text-base mb-4'>
                (c) MRZ Corporation. All rights reserved.
              </p>
              <div className='mb-1'>
                {/* Rowan Cap font */}
                <pre
                  className='text-[.35rem] -ml-10 sm:text-[.45rem] sm:-ml-14 md:text-[.55rem]
                  md:-ml-16 lg:text-[.65rem] lg:-ml-20 2xl:text-xs 2xl:-ml-24 mb-14'
                >{`
                   dMMMMMMMMb dMMMMb dMMMMMP        .dMMMb  dMP dMP .dMMMb dMMMMMMP dMMMMMP dMMMMMMMMb .dMMMb 
                   dMP"dMP"dMPdMP.dMP  .dMP"        dMP" VP dMP.dMP dMP" VP   dMP   dMP     dMP"dMP"dMPdMP" VP 
                  dMP dMP dMPdMMMMK" .dMP"          VMMMb   VMMMMP  VMMMb    dMP   dMMMP   dMP dMP dMP VMMMb   
                 dMP dMP dMPdMP"AMF.dMP"          dP .dMP dA .dMP dP .dMP   dMP   dMP     dMP dMP dMPdP .dMP   
                dMP dMP dMPdMP dMPdMMMMMP         VMMMP"  VMMMP"  VMMMP"   dMP   dMMMMMP dMP dMP dMP VMMMP"    
          `}</pre>
              </div>
              <div>
                <p className='mb-4'>Website services are loading...</p>
                <div className='flex flex-col gap-y-1 mb-4'>
                  {progressSentences.map((item, i) => (
                    <p
                      key={`prg-sentence-${i}`}
                      style={{
                        visibility:
                          (i + 1) * coefficient <= progress
                            ? 'visible'
                            : 'hidden',
                      }}
                    >
                      <span className='inline-block w-1 h-1 bg-white mr-2' />
                      <span>{item} &#10004;</span>
                    </p>
                  ))}
                </div>
                <p className='text-xl font-bold md:text-2xl lg:text-3xl 2xl:text-4xl mb-8'>
                  {progress.toFixed(2)}%
                </p>
              </div>
              <button
                className='bg-secondary py-2 px-4 text-sm md:text-base
                font-bold border-r-2 border-b-2 border-white outline-none
                self-start mb-auto'
                style={{ opacity: isLoaded ? 1 : 0 }}
                disabled={!isLoaded}
                onClick={() => setShow(false)}
              >{`press to enter >`}</button>
              <div
                className='w-full p-1 text-xs flex flex-col lg:flex-row gap-1 
                justify-between md:text-sm lg:text-base'
              >
                <p className='capitalize'>
                  Press <b>del</b> to enter <b>bios</b> settings
                </p>
                <p>{new Date().toTimeString()}</p>
              </div>
            </a.div>
          )
      )}
    </>
  );
};

export default Intro;

import { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import { Oval } from 'react-loader-spinner';
import { useTransition, animated as a } from '@react-spring/web';

import { POPUPS_ENDPOINT } from '../constants';

const Fullview = ({ currentMonitor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [src, setSrc] = useState(POPUPS_ENDPOINT);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
    setIsLoaded(false);
  };

  const handleLoaded = () => setIsLoaded(true);

  useEffect(() => {
    if (currentMonitor === 'center') setSrc(POPUPS_ENDPOINT);
    if (currentMonitor === 'services') setSrc(`${POPUPS_ENDPOINT}/services`);
    if (currentMonitor === 'gallery') setSrc(`${POPUPS_ENDPOINT}/gallery`);
    if (currentMonitor === 'mehanet') setSrc(`${POPUPS_ENDPOINT}/mehanet`);
    if (currentMonitor === 'mehaholding')
      setSrc(`${POPUPS_ENDPOINT}/mehaholding`);
  }, [currentMonitor]);

  const handleTransition = useTransition(!!currentMonitor, {
    from: { x: '-50%', y: 80, scaleX: 0.9, scaleY: 0.25 },
    enter: { x: '-50%', y: 0, scaleX: 0.9, scaleY: 0.25 },
    leave: { x: '-50%', y: 80, scaleX: 0.9, scaleY: 0.25 },
  });

  const btnTransition = useTransition(!!currentMonitor, {
    from: { bottom: '5%' },
    enter: { bottom: '20%' },
    leave: { bottom: '5%' },
  });

  return (
    <>
      {handleTransition(
        (style, item) =>
          item && (
            <a.div
              style={style}
              className="fixed bottom-0 left-1/2 -translate-x-1/2 z-20 
              min-[1100px]:hidden"
            >
              <img src="/images/handle.svg" alt="" />
            </a.div>
          )
      )}
      {btnTransition(
        (style, item) =>
          item && (
            <a.button
              style={style}
              className="fixed left-1/2 -translate-x-1/2 z-20 clip-path-3 text-white 
              p-[1px] pl-0 bg-white min-[1100px]:hidden"
              onClick={handleOpen}
            >
              <span
                className="inline-block w-full h-full clip-path-3 bg-gradient-to-b
              from-[#c1260f] to-[#ee642a] px-10 py-[6px] relative"
              >
                <span
                  className="absolute w-1/2 h-[1px] bg-white inline-block bottom-0
                  left-1/2 -translate-x-1/2"
                />
                Full View
              </span>
            </a.button>
          )
      )}
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-screen bg-white z-[10000]">
          <button
            className="absolute top-0 left-0 w-12 h-10 clip-path-1 bg-[#d9d9d9]
            flex justify-center items-center"
            onClick={handleClose}
          >
            <X className="w-5 h-5" />
          </button>
          {!isLoaded && (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <Oval
                width={50}
                height={50}
                color="#F64517"
                secondaryColor="#fa8f74"
                strokeWidth={5}
              />
            </div>
          )}
          <iframe
            title="popup"
            src={src}
            className="w-full h-full"
            onLoad={handleLoaded}
          />
        </div>
      )}
    </>
  );
};

export default Fullview;

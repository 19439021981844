import { useGLTF, Html } from '@react-three/drei';

import { OS_ENDPOINT } from '../constants';

const ServerMonitor = ({ iframeId, disable, ...props }) => {
  const { nodes, materials } = useGLTF('/models/server-monitor.glb');

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane028_1.geometry}
        material={materials['WIHTE SPECULARE METAL']}
      />
      <mesh
        geometry={nodes.Plane028.geometry}
        material={materials['MONITOR.002']}
      >
        <Html
          transform
          occlude='blending'
          distanceFactor={1}
          position={[0.04, 1.75, 1.625]}
          rotation-x={Math.PI * 0.12}
          className='w-[560px] h-[320px]'
          style={{ pointerEvents: disable ? 'none' : 'auto'}}
          zIndexRange={[2, 10]}
        >
          <iframe
            id={iframeId}
            title='embed'
            src={`${OS_ENDPOINT}/gallery`}
            className='w-full h-full'
          ></iframe>
        </Html>
      </mesh>
    </group>
  );
};

export default ServerMonitor;

useGLTF.preload('/models/server-monitor.glb');
